import React from 'react';
import { Button, Col } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import renderSelectField from '../../../../../shared/components/form/Select';
import validate from './validate';
import { connect } from 'react-redux';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';


let FinancialActivityFilterForm = ({handleSubmit, resetForm, reset, users}) => {
  return (
    <form onSubmit={handleSubmit} className="form w-100 form_FinancialActivityFilter mb-3">
      <Col lg={4} xl={2} xxl={3} className="col-12 col-xxl-3">
        <div className="form__form-group">
          <span className="form__form-group-label">Reporter</span>
          <div className="form__form-group-field">
            <Field
              name="selectReporter"
              component={renderSelectField}
              id="users"
              options={[{id: '', first_name: "All", last_name: ""}, ...users || []]?.map(u => {
                return {
                  value: u.id,
                  label: `${u.first_name} ${u.last_name}`
                }
              })}
            />
          </div>
        </div>
      </Col>
      <Col sm={12} lg={4} xl={3} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">From Date</span>
          <div className="form__form-group-field bg-white">
            <Field
              name="from_date"
              component={renderDatePickerField}
              max={new Date()}
            />
          </div>
        </div>
      </Col>
      <Col sm={12} lg={4} xl={3} className="col-12">
        <div className="form__form-group">
          <span className="form__form-group-label">To Date</span>
          <div className="form__form-group-field bg-white">
            <Field
              name="to_date"
              component={renderDatePickerField}
              maxDate={new Date()}
            />
          </div>
        </div>
      </Col>
      <Col lg={12} xl={4} xxl={3} className="col-12 d-flex align-items-center mt-1">
        <div className="form_financial-activity-filter-buttons d-flex justify-content-end w-100">
          <Button type="button" color="outline-secondary" onClick={() => { resetForm(); reset() }} style={{maxHeight: "32px", marginBottom: 0, padding: "5px 25px", whiteSpace: "nowrap"}}>
            Clear Filters
          </Button>
          <Button type="submit" color="primary" style={{maxHeight: "32px", marginBottom: 0, padding: "5px 25px", whiteSpace: "nowrap"}}>
            Apply Filters
          </Button>
        </div>
      </Col>
    </form>
  );
};

FinancialActivityFilterForm = reduxForm({
  form: 'financial_activity_filter_form',
  validate
})(FinancialActivityFilterForm);

FinancialActivityFilterForm = connect(
  state => ({
    initialValues: {
      from_date: new Date(new Date().getFullYear(),new Date().getMonth() - 1),
      to_date: new Date(new Date().getFullYear(), new Date().getMonth(), 0)
    }
  })
)(FinancialActivityFilterForm)

export default FinancialActivityFilterForm