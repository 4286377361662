import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import renderSelectField from '../../../../../../shared/components/form/Select';
import { connect } from 'react-redux';
import validate from './validate';
import _ from "lodash";
import {renderField} from '../../../../../../utils/helpers';
import renderDatePickerField from '../../../../../../shared/components/form/DatePicker';


let timerId;
let projectsTimerId;

let FormAddInvoices = ({
  handleSubmit,
  customer,
  business,
  methods,
  customerProjects,
  selectCustomer,
  selectCurrency,
  setQuery, 
  query,
  setQueryProjects,
  totalRecords,
  totalProjectsRecords,
}) => {
  return (
    <Card>
      <CardBody>
        <form className='form form-add-invoices' onSubmit={handleSubmit}>
          <Col className='col-12'>
            <h5 className='mb-2'>ADD INVOICE</h5>
          </Col>
          <Col className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Customer</span>
              <div className='form__form-group-field'>
                <Field
                  name="select_customer"
                  component={renderSelectField}
                  id="customer"
                  options={_.sortBy(customer, "first_name")?.map(c => {
                    return {
                      value: c.id,
                      label: `${c.first_name} ${c.last_name}`
                    }
                  })}
                  onInputChange={(value) => {
                    if(totalRecords <= 10) return;

                    clearTimeout(timerId);

                    timerId = setTimeout(() => {
                      if (!value) return setQuery({...query, search_query: ''});

                      setQuery({...query, search_query: value});
                    }, 600)
                  }}
                />
              </div>
            </div>
          </Col>
          {
            selectCustomer ? (
              <Col className="col-12">
                <div className='form__form-group'>
                  <span className='form__form-group-label'>Projects</span>
                  <div className='form__form-group-field'>
                    <Field
                      name="select_projects"
                      component={renderSelectField}
                      id="projects"
                      options={customerProjects?.map(c => {
                        return {
                          value: c.id,
                          label: `${c.name}`
                        }
                      })}
                      onInputChange={(value) => {
                        if(totalProjectsRecords <= 10) return;
    
                        clearTimeout(projectsTimerId);
    
                        projectsTimerId = setTimeout(() => {
                          setQueryProjects(value);
                        }, 600)
                      }}
                    />
                  </div>
                </div>
              </Col>
            ) : null
          }
          <Col className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Business</span>
              <div className='form__form-group-field'>
                <Field
                  name="select_business"
                  component={renderSelectField}
                  id="business"
                  options={business?.map(u => {
                    return {
                      value: u.id,
                      label: `${u.name}`
                    }
                  })}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Payment method</span>
              <div className='form__form-group-field'>
                <Field
                  name="select_method"
                  component={renderSelectField}
                  id="methods"
                  options={methods?.map(u => {
                    return {
                      value: u.id,
                      label: `${u.name}`
                    }
                  })}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" sm={6}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Date Start</span>
              <div className='form__form-group-field'>
                <Field
                  name="date_start"
                  component={renderDatePickerField}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" sm={6}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Date End</span>
              <div className='form__form-group-field'>
                <Field
                  name="date_end"
                  component={renderDatePickerField}
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" sm="4">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Subtotal</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name='subtotal'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" sm="4">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Discount</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name='discount'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" sm="4">
            <div className='form__form-group'>
              <span className='form__form-group-label'>Total</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name='total'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col className="col-12" sm={6}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Currency</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name="select_currency"
                  component={renderSelectField}
                  id="currency"
                  options={[{id: "USD", name: "USD"}, {id: "AUD", name: "AUD"}, {id: "EUR", name: "EUR"}, {id: "GBP", name: "GBP"}]?.map(u => {
                    return {
                      value: u.id,
                      label: `${u.name}`
                    }
                  })}
                />
              </div>
            </div>
          </Col>
          {
            selectCurrency?.value ===  "AUD" || selectCurrency?.value ===  "EUR" || selectCurrency?.value ===  "GBP" ? (
              <Col className="col-12" sm={6}>
                <div className='form__form-group'>
                  <span className='form__form-group-label'>Currency Exchange Rate</span>
                  <div className='form__form-group-field d-flex align-items-center'>
                    <Field
                      name='exchange_rate'
                      component={renderField}
                      type='input'
                    />
                  </div>
                </div>
              </Col>
            ) : null
          }
          <Col className="col-12" sm={6}>
            <div className='form__form-group'>
              <span className='form__form-group-label'>Notes</span>
              <div className='form__form-group-field d-flex align-items-center'>
                <Field
                  name='note'
                  component={renderField}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col md={6} className='col-12'>
            <div className='form__form-group'>
              <span className='form__form-group-label'>VAT Percentage</span>
              <div className='form__form-group-field'>
                <Field
                  name='vat_value'
                  component={renderField}
                  value={0}
                  type='input'
                />
              </div>
            </div>
          </Col>
          <Col sm={12} className='d-flex justify-content-end'>
            <button type="submit" className='btn btn-primary'>Save</button>
          </Col>
        </form>
      </CardBody>
    </Card>
  );
};

const selector = formValueSelector('formAddInvoices')

FormAddInvoices = reduxForm({
  form: 'formAddInvoices',
  validate
})(FormAddInvoices);

export default connect(state => ({
  selectCustomer: selector(state, 'select_customer'),
  selectCurrency: selector(state, 'select_currency')
}))(FormAddInvoices)