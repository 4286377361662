import React from 'react';
import { Col, Modal, ModalBody } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import validate from "./validate";
import { renderField } from '../../../../../utils/helpers';
import renderSelectField from '../../../../../shared/components/form/Select';
import renderDatePickerField from '../../../../../shared/components/form/DatePicker';


const MyInvoiceModalForm = ({toggle, modal, handleSubmit, users}) => {
  return (
    <div className="d-flex justify-content-center w-100">
      <Modal toggle={toggle} className="theme-light ltr-support add-income" isOpen={modal} style={{maxWidth: "400px", padding: "20px", margin: "28px auto"}}>
        <ModalBody>
          <form className='form form-add-my-invoice' onSubmit={handleSubmit}>
            <Col className="col-12">
              <div className='form__form-group'>
                <span className='form__form-group-label'>Date From</span>
                <div className='form__form-group-field'>
                  <Field
                    name="date_from"
                    component={renderDatePickerField}
                  />
                </div>
              </div>
            </Col>
            <Col className="col-12">
              <div className='form__form-group'>
                <span className='form__form-group-label'>Date To</span>
                <div className='form__form-group-field'>
                  <Field
                    name="date_to"
                    component={renderDatePickerField}
                  />
                </div>
              </div>
            </Col>
            <Col className="col-12">
              <div className='form__form-group'>
                <span className='form__form-group-label'>Users</span>
                <div className='form__form-group-field'>
                  <Field
                    name='user_id'
                    component={renderSelectField}
                    options={users?.map(u => {
                      return {
                        value: u.id,
                        label: `${u.first_name} ${u.last_name}`
                      }
                    })}
                  ></Field>
                </div>
              </div>
            </Col>
            <Col className="col-12">
              <div className='form__form-group'>
                <span className='form__form-group-label'>Amount</span>
                <div className='form__form-group-field d-flex align-items-center'>
                  <span style={{paddingRight: "5px", fontWeight: "bold"}}>$</span>
                  <Field
                    name='amount'
                    component={renderField}
                    type='input'
                  />
                </div>
              </div>
            </Col>
            <Col className='d-flex justify-content-end col-12'>
              <button type='submit' className='btn btn-primary'>Add</button>
            </Col>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default reduxForm({
  form: 'myInvoiceModalForm',
  validate
})(MyInvoiceModalForm);