import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getFinancialReportEdit, getFinancialReportIncome } from '../../../../../utils/api';
import _ from "lodash";
import moment from 'moment';
import Roles from '../../../../../config/roles';
import { useSelector } from 'react-redux';


const FinancialReportViewModal = ({toggle, modal, incomeId}) => {
  const [reports, setReports] = useState({});
  const [income, setIncome] = useState([]);  
  const authUser  = useSelector((state) => state.auth.user);

  useEffect(() => {
    getFinancialReportEdit(incomeId, authUser.accessToken)
      .then(res => setReports(res.data.data));

    getFinancialReportIncome({}, incomeId, authUser.accessToken)
      .then(res => setIncome(res.data.data.financial_incomes));
  }, [incomeId, authUser.accessToken])


  return (
    <Modal toggle={toggle} className="theme-light ltr-support view-modal" isOpen={modal} style={{maxWidth: "800px", padding: 0}}>
      <ModalHeader>VIEW INFO</ModalHeader>
      <ModalBody>
        <div className='c-view-report'>
          {
            authUser.role === Roles.ADMIN ? (
              <>
                <div className='c-view-report__row c-view-report__row--header'>
                  <div className="key">INCOME TOTAL:</div>
                  <div className="value">${_.sumBy(income, (i) => {
                    return Number(i.amount ? i.amount : 0)
                  }).toFixed(2)}</div>
                </div>
                <div className='c-view-report__row c-view-report__row--sub'>
                  {
                    income?.map((u) => {
                      return (
                        <div className="c-view-report__row__sub-item">
                          <div className="key">
                            <div>{u?.description} ON {moment(u.date).format('DD/MMMM/YYYY')}</div>
                            <div> {u?.project?.name}</div>
                          </div>
                          <div className="value">${u?.amount ? u?.amount.toFixed(2) : 0}</div>
                        </div>
                      )
                    })
                  }
                </div>
              </>
            ) : null
          }
          <div className="c-view-report__row c-view-report__row--header">
            <div className="key">CURRENCY RATE:</div>
            <div className="value">{reports?.currency}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">EXPENSES TOTAL:</div>
            <div className="value">${(_.sumBy(reports?.expense_constant, (c) => {
              return Number(c.amount ? c.amount : 0)
            }) + reports?.expense_salary).toFixed(2)}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">CONSTANT EXPENSES TOTAL:</div>
            <div className="value">${_.sumBy(reports?.expense_constant, (c) => {
              return Number(c.amount ? c.amount : 0)
            }).toFixed(2)}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--sub'>
            {
              reports?.expense_constant?.map((c) => {
                return (
                  <div className="c-view-report__row__sub-item">
                    <div className="key">{c.description}</div>
                    <div className="value">${c.amount ? Number(c.amount).toFixed(2) : "0.00"}</div>
                  </div>
                )
              })
            }
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">SALARY EXPENSES:</div>
            <div className="value">${reports?.expense_salary ? reports?.expense_salary.toFixed(2) : "0.00"}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">5.45% RESERVE FUND:</div>
            <div
              className="value">${((reports?.expense_salary ? reports?.expense_salary : 0) * 0.0545).toFixed(2)}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">THE DIFFERENCE:</div>
            <div className="value">${reports.different ? reports.different.toFixed(2) : "0.00"}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">10% BONUSES:</div>
            <div className="value">${reports.ten_percent_bonuses ? reports.ten_percent_bonuses.toFixed(2) : "0.00"}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--header'>
            <div className="key">5% CORPORATE EVENTS:</div>
            <div className="value">${reports.five_percent_corporate_event ? reports.five_percent_corporate_event.toFixed(2) : "0.00"}</div>
          </div>
          {
            authUser.role === Roles.ADMIN ? (
              <div className='c-view-report__row c-view-report__row--header'>
                <div className='key'>PROFIT:</div>
                <div className='value'>${reports.profit ? reports.profit.toFixed(2) : "0.00"}</div>
              </div>
            ) : null
          }
          <div className='c-view-report__row c-view-report__row--header'>
            <div className='key'>INVESTMENTS TOTAL:</div>
            <div className='value'>${(_.sumBy(reports?.investments, (i) => {
              return Number(i.amount ? i.amount : 0);
            })).toFixed(2)}</div>
          </div>
          <div className='c-view-report__row c-view-report__row--sub'>
            {
              reports?.investments?.map((i) => {
                return (
                  <div className='c-view-report__row__sub-item'>
                    <div className='key'>{i.description}</div>
                    <div className='value'>${i.amount ? Number(i.amount).toFixed(2) : "0.00"}</div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FinancialReportViewModal;